function supportsWebP(callback) {
  var img = new Image();
  img.onload = function() { callback(img.height === 2); };
  img.onerror = function() { callback(false); };
  img.src = 'data:image/webp;base64,UklGRi4AAABXRUJQVlA4TCEAAAAvAUAAEB8wAiMw' +
            'AgSSNtse/cXjxyCCmrYNWPwmHRH9jwMA';
}

function init() {
  var videoElement = document.getElementById('christmasVideo');

  if (videoElement && videoElement.canPlayType('video/mp4') === "") {
      videoElement.style.display = 'none';
      // Optionally, add a fallback background image here
      document.getElementById('video-background').style.background = '#000';
  }

  supportsWebP(function(supported) {
      var footerStyle = document.querySelector('.main-empty footer').style;
      if (supported) {
          footerStyle.background = 'url("berge_frei.webp") 50% 0 / cover no-repeat';
      } else {
          footerStyle.background = 'url("berge_frei.2d4d095d.png") 50% 0 / cover no-repeat';
      }
  });
}

window.onload = init;
